
import React from "react"
import { Col, Tab,ListGroup,Alert, Container,Row,Modal,Button } from "react-bootstrap"
import VisibilitySensor from "react-visibility-sensor"
import { Spring } from "react-spring/renderprops"
import {ArrowLeftCircleFill,Telephone,Envelope} from "react-bootstrap-icons"


// styles
const centeredStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  }
  
const Team = () => {

  return (

    <Row id="team">
    <Container
      style={{
        ...centeredStyles,
        overflow: "hidden",
     
      }}
    >
         

      <h1 >Team</h1>
      
      <VisibilitySensor partialVisibility>
        {({ isVisible }) => (
          <Spring
            delay={300}
            to={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateX(0)" : "translateX(200px)",
            }}
          >
            {props => (
              <Row style={{ ...props }}>
                <div>
                  <img src="../../team.jpg" width="100%"  style={{borderRadius:"10px"}}/>
                  <br/>  
                  <br/> 
               
                    <p style={{textAlign: "justify",paddingLeft:"20px",paddingRight:"20px", paddingTop:"20px"}}>Our team has decades of industry experience in each of our key sectors and plays a crucial role in helping clients achieve their objectives</p>  
                    <p style={{textAlign: "justify",paddingLeft:"20px",paddingRight:"20px"}}>
                      AIDIA Industrial team is broad, diverse and fully committed to working with integrity. What they have in common is a passion for innovation and making a difference. We thrive in a fast-growing environment and we thrive on working with the best of the best to build a better world.  
                    </p>
                    <p style={{textAlign: "justify",paddingLeft:"20px",paddingRight:"20px"}}>

                      <h4>AIDIA team is built on 5 pillars of victory: </h4>
                      </p>
                      </div> 
                <Tab.Container id="list-group-tabs-example" defaultActiveKey="#integrity">
                    <Row style={{width:"100%"}}>
                      <Col sm={4}>
                        <ListGroup>
                          <ListGroup.Item action href="#integrity">
                            <strong>INTEGRITY</strong>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#respect">
                            <strong>RESPECT</strong>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#performance">
                            <strong>PERFORMANCE</strong>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#simplicity">
                            <strong>SIMPLICITY</strong>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#accountability">
                            <strong>ACCOUNTABILITY</strong>
                          </ListGroup.Item>
                          
                        </ListGroup>
                      </Col>
                      <Col sm={8}>
                        <Tab.Content>
                          <Tab.Pane eventKey="#integrity"  style={{  padding: "70px 0",  textAlign: "center"}}>
                            <Alert variant={"primary"}>
                              <strong>Doing what is right and doing what we say we will do. Putting health and safety first, being environmentally responsible and supporting our communities.</strong>
                            </Alert>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#respect"  style={{  padding: "70px 0",  textAlign: "center"}}>
                          <Alert variant={"primary"}>
                              <strong>Embracing openness, trust, teamwork, diversity and relationships that are mutually beneficial. </strong>
                            </Alert>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#performance"  style={{  padding: "70px 0",  textAlign: "center"}}>
                            <Alert variant={"primary"}>
                              <strong>Achieving superior business results by stretching our capabilities.</strong>
                            </Alert>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#simplicity"  style={{  padding: "70px 0",  textAlign: "center"}}>
                            <Alert variant={"primary"}>
                              <strong>Focusing our efforts on the things that matter most.</strong>
                            </Alert>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#accountability"  style={{  padding: "70px 0",  textAlign: "center"}}>
                            <Alert variant={"primary"}>
                              <strong>Defining and accepting responsibility and delivering on our commitments.</strong>
                            </Alert>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>

                    </Row>

                  </Tab.Container>
                  <br/>
                  <hr/>

               </Row>
            )}
          </Spring>
        )}
      </VisibilitySensor>
    </Container>
  </Row>

)
}

export default Team